import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import createPersistedState from "vuex-persistedstate";

const axios = require("axios");
const host = require("@/config/hosts").hostAPI;

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state() {
    return {
      tpcsAtiv: [],
      tpcsExp: [],
      tpcsPend: [],
      isEditing: false,
    };
  },
  mutations: {
    setTpcsAtiv(state, payload) {
      state.tpcsAtiv = payload.tpcsAtiv;
    },
    setTpcsExp(state, payload) {
      state.tpcsExp = payload.tpcsExp;
    },
    setTpcsPend(state, payload) {
      state.tpcsPend = payload.tpcsPend;
    },
    setTpcs(state, payload) {
      state.tpcsAtiv = payload.tpcsAtiv;
      state.tpcsExp = payload.tpcsExp;
      state.tpcsPend = payload.tpcsPend;
    },
    setEditFlag(state, payload) {
      state.isEditing = payload.isEditing;
    },
  },
  actions: {
    async getTpcs(context) {
      try {
        const type = context.getters.getUserType;
        const userId = context.getters.getUserId;
        if (type === "professor") {
          const resp1 = await axios.get(
            host + "tpcs/prof/" + userId + "?active=true"
          );
          const tpcsAtiv = resp1.data.tpcs.map((el) => el.id);

          const resp2 = await axios.get(
            host + "tpcs/prof/" + userId + "?active=false"
          );
          const tpcsExp = resp2.data.tpcs.map((el) => el.id);

          const resp3 = await axios.get(
            host + "tpcs/prof/" + userId + "?active=pending"
          );
          const tpcsPend = resp3.data.tpcs.map((el) => el.id);
          // Store Storage
          context.commit("setTpcs", {
            tpcsAtiv,
            tpcsExp,
            tpcsPend,
          });
        } else if (type === "aluno") {
          let tpcsAtiv = [];
          let tpcsExp = [];

          const resp1 = await axios.get(
            host + "tpcs/aluno/" + userId + "?active=true"
          );
          tpcsAtiv = resp1.data.tpcs
            .filter((el) => el.nResol < el.tentativas)
            .map((el) => el.id);

          const resp2 = await axios.get(
            host + "tpcs/aluno/" + userId + "?active=false"
          );
          tpcsExp = resp2.data.tpcs.map((el) => el.id);

          // Store Storage
          context.commit("setTpcs", {
            tpcsAtiv,
            tpcsExp,
          });
        }
      } catch (err) {
        const error = new Error(err.message);
        throw error;
      }
    },
  },
  getters: {
    getTpcsAtiv(state) {
      return state.tpcsAtiv;
    },
    getTpcsExp(state) {
      return state.tpcsExp;
    },
    getTpcsPend(state) {
      return state.tpcsPend;
    },
    getEditFlag(state) {
      return state.isEditing;
    },
  },
  modules: {
    auth,
  },
});
