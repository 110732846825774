<template>
  <div class="grey lighten-3">
    <component :is="pending"></component>
  </div>
</template>

<script>
import ProfPending from "@/components/ProfPending.vue";
import AlunoDash from "@/components/AlunoDash.vue";

export default {
  created() {},
  components: {
    ProfPending,
    AlunoDash,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    pending() {
      const usertype = this.$store.getters.getUserType;
      if (usertype === "professor") {
        return "ProfPending";
      } else return "AlunoDash";
    },
  },
};
</script>

<style></style>
